import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../auth/auth.guard';
import { DafComponent } from './daf.component';
import { CreateDafComponent } from './create-daf/create-daf.component';
import { DetailDafComponent } from './detail-daf/detail-daf.component';

const routes: Routes = [
  {
    path: '',
    component: DafComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'adicionar',
    component: CreateDafComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':id',
    component: DetailDafComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DafRoutingModule { }
