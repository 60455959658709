import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  public registerUrl: string = environment.registerUrl;

  constructor(
    public auth: AuthService,
    public errorHandler: ErrorHandlerService,
    public router: Router
  ) {
  }

  ngOnInit(): void {
  }

  get user() {
    return this.auth.getLoggedUser();
  }

  logout() {
    this.auth.logout();
  }

  redirectToLogin() {
    this.auth.redirectToLogin();
  }

  get isLoggedIn() {
    return this.auth.isLoggedIn;
  }
}
