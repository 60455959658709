import { Component, OnInit } from '@angular/core';
import { DirectionOutputDTO } from '../core/types/direction';
import { ErrorHandlerService } from '../core/error-handler.service';
import { DirectionService } from './direction.service';
import { finalize } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-direction',
  templateUrl: './direction.component.html',
  styleUrls: ['./direction.component.scss']
})
export class DirectionComponent implements OnInit {
  loading = false;
  data: DirectionOutputDTO[] = [];

  constructor(
    private directionService: DirectionService,
    private errorHandler: ErrorHandlerService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.loadDirections();
  }

  loadDirections() {
    this.loading = true;
    this.directionService.loadDirections()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.data = data,
        error: (error) => this.errorHandler.handle(error),
      })
  }
}
