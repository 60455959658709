import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { CreateDafComponent } from './create-daf/create-daf.component';
import { DafRoutingModule } from './daf-routing.module';
import { DafComponent } from './daf.component';
import { DafService } from './daf.service';
import { AppButtonModule } from '../shared/components/app-button/app-button.module';
import { DetailDafComponent } from './detail-daf/detail-daf.component';
import { AppTableModule } from '../shared/components/app-table/app-table.module';

@NgModule({
  declarations: [
    DafComponent,
    CreateDafComponent,
    DetailDafComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DafRoutingModule,
    MDBBootstrapModule,
    TableModule,
    InputTextModule,
    ProgressBarModule,
    AppButtonModule,
    AppTableModule
  ],
  providers: [DafService]
})
export class DafModule { }
