import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ActivationGuard } from './auth/activation.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'dafs',
    canActivate: [AuthGuard, ActivationGuard],
    loadChildren: () =>
      import('./daf/daf.module').then((m) => m.DafModule),
  },
  {
    path: 'directions',
    canActivate: [AuthGuard, ActivationGuard],
    loadChildren: () =>
      import('./direction/direction.module').then((m) => m.DirectionModule),
  },
  {
    path: 'tecnicos',
    canActivate: [AuthGuard, ActivationGuard],
    loadChildren: () =>
      import('./employee/employee.module').then((m) => m.EmployeeModule),
  },
  {
    path: 'documentos',
    canActivate: [AuthGuard, ActivationGuard],
    loadChildren: () =>
      import('./document/document.module').then((m) => m.DocumentModule),
  },
  {
    path: 'historico',
    canActivate: [AuthGuard, ActivationGuard],
    loadChildren: () =>
      import('./historic/historic.module').then((m) => m.HistoricModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
