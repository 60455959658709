import { Component, OnInit } from '@angular/core';
import { DafService } from '../daf.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DafOutputDTO } from 'src/app/core/types/daf';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from 'src/app/employee/employee.service';
import { EmployeeOutputDTO } from 'src/app/core/types/employee';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-detail-daf',
  templateUrl: './detail-daf.component.html',
  styleUrls: ['./detail-daf.component.scss']
})
export class DetailDafComponent implements OnInit {
  loadingDaf: boolean = false;
  loadingEmployees: boolean = false;

  daf!: DafOutputDTO;
  employees: EmployeeOutputDTO[] = [];

  constructor(
    private dafService: DafService,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getDafById(params['id']);
      this.loadEmployeesByDafId(params['id']);
    })
  }

  getDafById(id: string) {
    this.loadingDaf = true;
    this.dafService
      .getDafById(id)
      .pipe(finalize(() => this.loadingDaf = false))
      .subscribe({
        next: (data) => this.daf = data,
        error: (error) => this.errorHandler.handle(error),
      });
  }

  loadEmployeesByDafId(dafId: string) {
    this.loadingEmployees = true;
    this.employeeService
      .loadByDafId(dafId)
      .pipe(finalize(() => this.loadingEmployees = false))
      .subscribe({
        next: (data) => this.employees = data,
        error: (error) => this.errorHandler.handle(error),
      });
  }
}
