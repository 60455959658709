<p-table
  styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
  [value]="items"
  [loading]="loading"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Descrição</th>
      <th>Montante</th>
      <th>%IVA</th>
      <th>IVA</th>
      <th>Código i/s</th>
      <th>Total</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <span class="p-column-title">Descrição</span>
        {{ rowData.description }}
      </td>
      <td>
        <span class="p-column-title">Montante</span>
        {{ rowData.amount | number : "0.2-6" : "pt" }}
      </td>
      <td>
        <span class="p-column-title">%IVA</span>
        {{ rowData.tax }}
      </td>
      <td>
        <span class="p-column-title">IVA</span>
        {{ rowData.taxAmount | number : "0.2-6" : "pt" }}
      </td>
      <td>
        <span class="p-column-title">Código i/s</span>
        {{ rowData.incidenceCode }}
      </td>
      <td>
        <span class="p-column-title">Total</span>
        {{ rowData.totalAmount | number : "0.2-6" : "pt" }}
      </td>
    </tr>
  </ng-template>
</p-table>