import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { DetailDirectionComponent } from './detail-direction/detail-direction.component';
import { CreateDirectionComponent } from './create-direction/create-direction.component';
import { DirectionComponent } from './direction.component';
import { ManagerGuard } from '../auth/manager.guard';

const routes: Routes = [
  {
    path: '',
    component: DirectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'adicionar',
    component: CreateDirectionComponent,
    canActivate: [AuthGuard, ManagerGuard],
  },
  {
    path: ':id',
    component: DetailDirectionComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DirectionRoutingModule { }
