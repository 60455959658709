import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmployeeInputDTO, EmployeeOutputDTO } from '../core/types/employee';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  apiURL: string;

  constructor(private httpClient: HttpClient) {
    this.apiURL = `${environment.apiUrl}/employees`;
  }

  loadEmployees(): Observable<EmployeeOutputDTO[]> {
    return this.httpClient.get<EmployeeOutputDTO[]>(this.apiURL);
  }

  loadByDafId(dafId: string): Observable<EmployeeOutputDTO[]> {
    let params = new HttpParams();
    params = params.append('ministryId', dafId)
    return this.httpClient
      .get<EmployeeOutputDTO[]>(`${this.apiURL}/ministry`, { params });
  }

  getEmployeeById(id: string): Observable<EmployeeOutputDTO> {
    return this.httpClient.get<EmployeeOutputDTO>(`${this.apiURL}/${id}`);
  }

  createEmployee(data: EmployeeInputDTO): Observable<EmployeeOutputDTO> {
    return this.httpClient.post<EmployeeOutputDTO>(this.apiURL, data);
  }

  setActivation(id: string, isActive: boolean): Observable<void> {
    return this.httpClient
      .patch<void>(`${this.apiURL}/${id}/activation`, { isActive })
  }

  setManager(id: string, isManager: boolean): Observable<void> {
    return this.httpClient
      .patch<void>(`${this.apiURL}/${id}/manager`, { isManager })
  }
}
