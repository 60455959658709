import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppHttp } from 'src/app/auth/app-http';
import { environment } from 'src/environments/environment';
import { DocumentFIlter, DocumentOutputDTO, DocumentPaginated } from '../core/types/document';
import { DateService } from '../core/date.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  API_URL: string;

  constructor(
    private httpClient: AppHttp,
    private dateService: DateService,
  ) {
    this.API_URL = `${environment.apiUrl}/documents`;
  }

  loadDocuments(filter: DocumentFIlter): Observable<DocumentPaginated> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.size.toString());
    return this.httpClient.get<DocumentPaginated>(`${this.API_URL}`, { params })
      .pipe(map((data) => {
        const content = data.content as DocumentOutputDTO[];
        this.convertData(content);
        return {...data, content } 
      }));
  }

  getDocumentById(id: string): Observable<DocumentOutputDTO> {
    return this.httpClient.get<DocumentOutputDTO>(`${this.API_URL}/${id}`)
      .pipe(map((data) => {
        const document = data as DocumentOutputDTO;
        this.convertData([document]);
        return document; 
      }));
  }

  private convertData(documents: DocumentOutputDTO[]) {
    for (const document of documents) {
      document.date = document.date && this.dateService.getStringDate(new Date(document.date));
    }
  }
}
