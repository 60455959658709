import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppHttp } from '../auth/app-http';
import { HistoricFIlter, HistoricPaginated } from '../core/types/historic';

@Injectable({
  providedIn: 'root'
})
export class HistoricService {
  API_URL: string;

  constructor(private httpClient: AppHttp) {
    this.API_URL = `${environment.apiUrl}/histories`;
  }

  loadHistories(
    filter: HistoricFIlter = { page: 0, size: 10 }
  ): Observable<HistoricPaginated> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.size.toString());
    return this.httpClient.get<HistoricPaginated>(`${this.API_URL}`, { params });
  }
}
