<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          HISTÓRICO DE ACÇÕES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>
  
  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body p-4">
        <div class="p-col-6">
          <input pInputText class="p-inputtext-sm" name="search" placeholder="Search">
        </div>
        <div class="p-col-12">
          <p-table
            styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
            [value]="historicPaginated?.content"
            [loading]="loading"
            [paginator]="true"
            [rows]="filter?.size"
            [lazy]="true"
            [totalRecords]="historicPaginated?.totalElements"
            (onLazyLoad)="onChangePage($event)"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Utilizador</th>
              <th>Descrição da Acção</th>
              <th>Data</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Utilizador</span>
                {{ rowData.authUser.username }} - {{ rowData.authUser.name }}
              </td>
              <td>
                <span class="p-column-title">Descrição da Acção</span>
                <a href="javascript:void(0)" class="text-primary" (click)="showDataInModal(rowData)">
                  {{ rowData.description }}
                </a>
              </td>
              <td>
                <span class="p-column-title">Data</span>
                {{ rowData.createdAt | date : "dd/MM/yyyy hh:mm:ss" }}
              <td>
                <span class="p-column-title">Informações</span>
                {{ rowData.date }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Informações da Ação" [(visible)]="displayModal" [style]="{width: '50vw'}">
  <ngx-json-viewer [json]="dataAfter" [expanded]="false"></ngx-json-viewer>
  <ng-template pTemplate="footer">
    <app-button
      label="Fechar"
      color="light"
      iconRight="fa-close"
      size="sm"
      (onClick)="displayModal=false">
    </app-button>
  </ng-template>
</p-dialog>