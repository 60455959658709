import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DirectionInputDTO, DirectionOutputDTO } from '../core/types/direction';

@Injectable({
  providedIn: 'root'
})
export class DirectionService {
  apiURL: string;

  constructor(private httpClient: HttpClient) { 
    this.apiURL = `${environment.apiUrl}/directions`;
  }

  loadDirections(): Observable<DirectionOutputDTO[]> {
    return this.httpClient.get<DirectionOutputDTO[]>(this.apiURL);
  }

  getDirectionById(id: string): Observable<DirectionOutputDTO> {
    return this.httpClient.get<DirectionOutputDTO>(`${this.apiURL}/${id}`);
  }

  createDirection(data: DirectionInputDTO): Observable<DirectionOutputDTO> {
    return this.httpClient.post<DirectionOutputDTO>(this.apiURL, data);
  }
}
