import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationService } from '../validation.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DocumentValidationForm } from '../document-validation.form';
import { finalize } from 'rxjs';
import { DocumentOutputDTO, DocumentValidationExemptionReason, DocumentValidationStatus } from 'src/app/core/types/document';

@Component({
  selector: 'app-daf-validation',
  templateUrl: './daf-validation.component.html',
  styleUrls: ['./daf-validation.component.scss']
})
export class DafValidationComponent implements OnInit {
  @Input() documentId: string;
  @Output() updateDocument: EventEmitter<DocumentOutputDTO> = new EventEmitter<DocumentOutputDTO>();

  loading = false;
  displayModal = false;

  form: FormGroup;

  exemptionsReason: { label: string, value: string }[];
  validationsStatus: { label: string, value: string }[];

  constructor(
    private validationService: ValidationService,
    private validationForm: DocumentValidationForm,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.exemptionsReason = this.getExemptionsReason();
    this.validationsStatus = this.getValidationsStatus();

    this.form = this.validationForm.buildForm();
  }

  isDisabled() {
    return this.form.invalid || this.loading;
  }

  showValidationModalForm() {
    this.displayModal = true;
  }

  getExemptionsReason() {
    return  Object.keys(DocumentValidationExemptionReason)
      .map((key) => ({ 
        label: DocumentValidationExemptionReason[key], 
        value: key 
      }));
  }

  getValidationsStatus() {
    return Object.keys(DocumentValidationStatus)
      .map((key) => ({ 
        label: DocumentValidationStatus[key], 
        value: key 
      }));
  }

  validate() {
    if (this.form.valid) {
      this.loading = true;

      const data = this.validationForm.parseForm();

      this.validationService
        .dafValidation(this.documentId, data)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (data) => {
            this.displayModal = false;
            this.form.reset();
            this.updateDocument.emit(data);
          },
          error: (error) => this.errorHandler.handle(error),
        });
    }
  }

  submitForm() {
    const ngForm = document.querySelector('form');
    if (ngForm) {
      ngForm.dispatchEvent(new Event('submit'));
    }
  }
}
