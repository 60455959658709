<section id="topbar" class="d-none d-lg-block">
  <div class="container clearfix">
    <div class="contact-info float-left text-uppercase">
      <a href="javascript:void(0)">
        Ministério do Planeamento, Finanças e Economia Azul
      </a>
    </div>

    <div
      class="social-links float-right"
      *ngIf="isLoggedIn; else elseIsLoggedIn"
    >
      <a
        href="javascript:void(0)"
        class="text-ellipses"
        [title]="user && user.given_name"
      >
        <i class="fas fa-user"></i>
        {{ user && user.given_name }}
      </a>
      <a (click)="logout()"> <i class="fas fa-sign-in-alt"></i> Sair </a>
    </div>

    <ng-template #elseIsLoggedIn>
      <div class="social-links float-right">
        <a (click)="redirectToLogin()">
          <i class="fa fa-sign-in-alt"></i> Entrar
        </a>

        <a [attr.href]="registerUrl">
          <i class="fas fa-user-plus"></i> Registar
        </a>
      </div>
    </ng-template>
  </div>
</section>
