<app-button
  type="submit"
  label="Validar (Orçamento)"
  color="primary"
  iconRight="fa-check"
  (onClick)="showValidationModalForm()">
</app-button>

<p-dialog header="Validação (Orçamento)" [(visible)]="displayModal" [style]="{width: '50vw'}">
  <form
    [formGroup]="form"
    (ngSubmit)="validate()"
    class="text-left"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label [ngStyle]="{width: '100%'}">Status</label>
        <p-dropdown
          [options]="validationsStatus"
          placeholder="Selecione"
          [ngStyle]="{width: '100%'}"
          formControlName="status">
        </p-dropdown>
      </div>

      <div class="p-field p-col-12" *ngIf="form.get('status')?.value === 'REJECTED'">
        <label>Razão da Rejeição</label>
        <p-dropdown
          [options]="exemptionsReason"
          placeholder="Selecione"
          [ngStyle]="{width: '100%'}"
          formControlName="rejectionReason"
          [showClear]="true">
        </p-dropdown>
      </div>

      <div class="p-field p-col-12">
        <label>Observação</label>
        <textarea rows="5" pInputTextarea formControlName="obs"></textarea>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <app-button
      label="Cancelar"
      color="light"
      iconRight="fa-close"
      size="sm"
      (onClick)="displayModal=false">
    </app-button>

    <app-button
      label="Confirmar"
      color="primary"
      iconRight="fa-check"
      size="sm"
      [loading]="loading"
      [disabled]="isDisabled()"
      (onClick)="submitForm()">
    </app-button>
  </ng-template>
</p-dialog>