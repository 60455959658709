<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DIRECÇÕES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 text-right">
    <app-button-route
      *ngIf="auth.profile.isManager"
      label="Adicionar"
      color="primary"
      routerLink="/directions/adicionar"
      iconRight="fa-plus"></app-button-route>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body p-4">
        <div class="p-col-6">
          <input pInputText class="p-inputtext-sm" name="search" placeholder="Search">
        </div>
        <div class="p-col-12">
          <p-table
            styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
            [value]="data"
            [loading]="loading"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>NIF - Nome</th>
                <th>Acrónimo</th>
                <th *ngIf="auth.profile?.rule === 'ADMIN' || auth.profile?.rule === 'AGENT'">DAF</th>
                <th [style]="{ width: '3em' }"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td>
                  <span class="p-column-title">NIF - Nome</span>
                  {{ rowData.nif }} - {{ rowData.name }}
                </td>
                <td>
                  <span class="p-column-title">Acrónimo</span>
                  {{ rowData.acronym }}
                </td>
                <td *ngIf="auth.profile?.rule === 'ADMIN' || auth.profile?.rule === 'AGENT'">
                  <span class="p-column-title">DAF</span>
                  {{ rowData.ministry.nif }} - {{ rowData.ministry.name }}
                </td>
                <td [style]="{ padding: '1px 4px' }">
                  <button
                    type="button"
                    mdbBtn
                    color="info"
                    size="sm"
                    pTooltip="Ver mais informações"
                    tooltipPosition="left"
                    class="btn-block"
                    [style]="{ padding: '4px 0' }"
                    [routerLink]="[
                      '/directions',
                      rowData._id
                    ]"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
