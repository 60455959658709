import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaxIdValidator } from "../taxpayer/tax-id.validator";
import { EmployeeInputDTO } from "../core/types/employee";

@Injectable({
  providedIn: 'root',
})
export class EmployeeForm {
  form: FormGroup;

  constructor(
    private taxIdValidator: TaxIdValidator,
  ) { }

  buildForm() {
    return this.form = new FormGroup({
      name: new FormControl(''),
      nif: new FormControl('', {
        validators: [Validators.required, Validators.pattern(/^\d{9}$/)],
        asyncValidators: [this.taxIdValidator.validate.bind(this.taxIdValidator)],
        updateOn: 'blur',
      }),
    });
  }

  fillTaxpayerName() {
    this.form.get('nif')?.statusChanges.subscribe((status) => {
      this.form.get('name')?.reset();
      if (status === 'VALID') {
        const taxpayer = this.taxIdValidator.getTaxpayer();
        if (taxpayer) {
          this.form.get('name')?.setValue(taxpayer.name);
        }
      }
    });
  }

  parseForm(): EmployeeInputDTO {
    return {
      nif: this.taxId.value,
    }
  }

  get taxId() {
    return this.form.get('nif') as FormControl<string>;
  }

  get name() {
    return this.form.get('name') as FormControl<string>;
  }

  get acronym() {
    return this.form.get('acronym') as FormControl<string>;
  }
}