<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/dafs">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DAFs
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 text-right">
    <app-button-route
      label="Voltar"
      color="light"
      routerLink="/dafs"
      iconLeft="fa-arrow-circle-left">
    </app-button-route>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <div class="p-grid">
          <div class="p-col-12">
            <table mdbTable>
              <tbody>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bolder border-0">NIF</th>
                <td class="border-0">{{ daf?.nif }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">NOME</th>
                <td>{{ daf?.name }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">ACRÓNIMO</th>
                <td>{{ daf?.acronym }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="p-col-12">
            <hr />
            <h6>TÉCNICOS</h6>
            <p-table
              styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
              [value]="employees"
              [loading]="loadingEmployees"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>NIF</th>
                  <th>Nome</th>
                  <th>Gestor</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td>
                    <span class="p-column-title">NIF</span>
                    {{ rowData.nif }}
                  </td>
                  <td>
                    <span class="p-column-title">Nome</span>
                    {{ rowData.name }}
                  </td>
                  <td>
                    <span class="p-column-title">Gestor</span>
                    {{ rowData.isManager ? 'Gestor' : 'Básico' }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
