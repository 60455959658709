import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentValidationExemptionReason, DocumentValidationInputDTO, DocumentValidationStatus } from "../core/types/document";

@Injectable({
  providedIn: 'root',
})
export class DocumentValidationForm {
  form: FormGroup;

  constructor() { }

  buildForm() {
    this.form = new FormGroup({
      status: new FormControl('', [Validators.required]),
      rejectionReason: new FormControl('', []),
      obs: new FormControl('', Validators.required),
    });

    // Subscribe to status value changes to update rejectionReason validation
    this.status!.valueChanges.subscribe((status) => {
      this.rejectionReason.setValidators(
        status?.toString() === 'REJECTED'
          ? [Validators.required] 
          : []
        );
      this.rejectionReason.updateValueAndValidity();
    });

    return this.form;
  }

  parseForm(): DocumentValidationInputDTO {
    return {
      status: this.status.value,
      rejectionReason: this.rejectionReason.value,
      obs: this.obs.value,
    }
  }

  get status() {
    return this.form.get('status') as FormControl<DocumentValidationStatus>;
  }

  get rejectionReason() {
    return this.form.get('rejectionReason') as FormControl<DocumentValidationExemptionReason>;
  }

  get obs() {
    return this.form.get('obs') as FormControl<string>;
  }
}