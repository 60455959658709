<button
  type="button"
  mdbBtn
  mdbWavesEffect
  [color]="color"
  [size]="size"
  [routerLink]="routerLink"
  [disabled]="disabled"
>
  <i *ngIf="iconLeft" [ngClass]="['fa', iconLeft]"></i>
  {{label}}
  <i *ngIf="iconRight" [ngClass]="['fa', iconRight]"></i>
</button>