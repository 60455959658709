<button
  [attr.type]="type"
  mdbBtn
  mdbWavesEffect
  [color]="color"
  [disabled]="disabled"
  [size]="size"
  (click)="onClick.emit($event)"
>
  <ng-container *ngIf="!loading; else loadingIcon">
    <i *ngIf="iconLeft" [ngClass]="['fa', iconLeft]"></i>
    {{label}}
  <i *ngIf="iconRight" [ngClass]="['fa', iconRight]"></i>
  </ng-container>

  <ng-template #loadingIcon>
    {{label}}
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">Loading...</span>
  </ng-template>
  
</button>