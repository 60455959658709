<p-table
  styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
  [value]="histories"
  [loading]="loading"
  [paginator]="true"
  [rows]="5"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Técnico</th>
      <th>Validação</th>
      <th>Data da Validação</th>
      <th>Razão da Rejeição</th>
      <th [style]="{ width: '3em' }"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <span class="p-column-title">Técnico</span>
        {{ rowData.nifValidator }} - {{ rowData.nameValidator }}
      </td>
      <td>
        <span class="p-column-title">Validação</span>
        <ng-container *ngIf="rowData.status === 'ACCEPTED'">
          <p-tag severity="success" value="ACEITO"></p-tag>
        </ng-container>
        <ng-container *ngIf="rowData.status === 'REJECTED'">
          <p-tag severity="danger" value="REJEITADO"></p-tag>
        </ng-container>
        <ng-container *ngIf="rowData.status === 'PENDING'">
          <p-tag severity="warning" value="PENDENTE"></p-tag>
        </ng-container>
      </td>
      <td>
        <span class="p-column-title">Data da Validação</span>
        {{ rowData.validatedAt | date : "dd/MM/yyyy hh:mm:ss" }}
      </td>
      <td>
        <span class="p-column-title">Razão da Rejeição</span>
        {{ getExemptionReason(rowData.rejectionReason) || '---' }}
      </td>
      <td [style]="{ padding: '1px 4px' }">
        <button
          type="button"
          mdbBtn
          color="info"
          size="sm"
          pTooltip="Ver observação"
          tooltipPosition="left"
          class="btn-block"
          [style]="{ padding: '4px 0' }"
          (click)="showMoreDetailsInModalDialog(rowData)"
        >
          <i class="fa fa-info-circle"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Observação" [(visible)]="displayModal" [style]="{width: '50vw'}">
  <p>{{ history?.obs }}</p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>