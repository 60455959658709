<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/directions">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DIRECÇÕES
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 text-right">
    <app-button-route
      label="Voltar"
      color="light"
      routerLink="/directions"
      iconLeft="fa-arrow-circle-left">
    </app-button-route>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <div class="p-grid">
          <div class="p-col-12">
            <table mdbTable>
              <tbody>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bolder border-0">NIF</th>
                <td class="border-0">{{ direction?.nif }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">NOME</th>
                <td>{{ direction?.name }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">ACRÓNIMO</th>
                <td>{{ direction?.acronym }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">DAF</th>
                <td>
                  {{ direction?.ministry.nif }} -
                  {{ direction?.ministry.name }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
