import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AppMessageService {

  constructor(private messageService: MessageService) { }

  addSuccess({ summary, detail }) {
    this.messageService.add({ severity:'success', summary, detail,life: 5000 })
  }

  addError({ summary, detail }) {
    this.messageService.add({ severity:'error', summary, detail,life: 5000 })
  }

  addInfo({ summary, detail }) {
    this.messageService.add({ severity:'info', summary, detail,life: 5000 })
  }

  addWarn({ summary, detail }) {
    this.messageService.add({ severity:'warn', summary, detail,life: 5000 })
  }
}
