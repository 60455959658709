import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmployeeService } from '../employee.service';
import { EmployeeForm } from '../employee.form';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { EmployeeInputDTO } from 'src/app/core/types/employee';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss']
})
export class CreateEmployeeComponent implements OnInit {
  loading = false;
  form: FormGroup;

  constructor(
    private employeeService: EmployeeService,
    private employeeForm: EmployeeForm,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.form = this.employeeForm.buildForm();
    this.employeeForm.fillTaxpayerName();
  }

  isDisabled() {
    return this.form.invalid || this.loading;
  }

  addEmployee() {
    if (this.form.valid) {
      this.loading = true;
      const data: EmployeeInputDTO = this.employeeForm.parseForm();
      this.employeeService
        .createEmployee(data)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (data) => this.router.navigate(['tecnicos', data._id]),
          error: (error) => this.errorHandler.handle(error),
        });
    }
  }
}
