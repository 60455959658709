import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { DocumentComponent } from './document.component';
import { DetailDocumentComponent } from './detail-document/detail-document.component';

const routes: Routes = [
  {
    path: '',
    component: DocumentComponent,
  },
  {
    path: ':id',
    component: DetailDocumentComponent,
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DocumentRoutingModule { }
