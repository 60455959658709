import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionComponent } from './direction.component';
import { CreateDirectionComponent } from './create-direction/create-direction.component';
import { DetailDirectionComponent } from './detail-direction/detail-direction.component';
import { DirectionService } from './direction.service';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TableModule } from 'primeng/table';
import { AppButtonModule } from '../shared/components/app-button/app-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectionRoutingModule } from './direction-routing.module';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [
    DirectionComponent,
    CreateDirectionComponent,
    DetailDirectionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectionRoutingModule,
    MDBBootstrapModule,
    TableModule,
    InputTextModule,
    AppButtonModule
  ],
  providers: [DirectionService]
})
export class DirectionModule { }
