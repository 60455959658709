import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { RouterModule } from '@angular/router';
import { ButtonRouteComponent } from './button-route/button-route.component';
import { ButtonComponent } from './button/button.component';

@NgModule({
  declarations: [
    ButtonRouteComponent,
    ButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MDBBootstrapModule,
  ],
  exports: [ButtonRouteComponent, ButtonComponent]
})
export class AppButtonModule { }
