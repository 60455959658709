import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TaxpayerOutputDTO } from '../core/types/taxpayer';

@Injectable({
  providedIn: 'root'
})
export class TaxpayerService {
  apiURL: string;

  constructor(private httpClient: HttpClient) { 
    this.apiURL = `${environment.apiUrl}/taxpayers`;
  }

  getTaxpayerByTaxId(taxId: string) {
    return this.httpClient.get<TaxpayerOutputDTO>(`${this.apiURL}/${taxId}`);
  }
}
