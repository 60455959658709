export type DocumentOutputDTO = {
  _id: string;
  emitterNif: string,
  emitterName: string,
  customerNif: string,
  customerName: string,
  date: string,
  serie: string,
  reference: string,
  direction: {
    _id: string,
    name: string,
    acronym: string,
    nif: string,
    ministry: string,
	},
  items: [{
    _id: string,
    amount: number,
    description: string,
    tax: number,
    incidenceCode: string,
    taxAmount: number,
    totalAmount: number,
    documentOrigins: [],
  }],
  amount: number,
  taxAmount: number,
  totalAmount: number,
  dafValidation: {
    status: DocumentValidationStatus,
    obs: string,
    rejectionReason: string,
    nifValidator: string,
    _id: string,
    createdAt: Date,
    updatedAt: Date
  },
  budgetValidation: {
    _id: string,
    status: DocumentValidationStatus,
    obs: string,
    nifValidator: string,
    createdAt: Date,
    updatedAt: Date,
    rejectionReason: string,
  },
  createdAt: Date,
  updatedAt: Date,
}

export type DocumentPaginated = {
  content: DocumentOutputDTO[],
  totalPages: number,
	totalElements: number,
	size: number,
	empty: boolean,
	first: boolean,
	last: boolean,
	number: number
}

export type DocumentFIlter = {
  page: number;
  size: number;
}

export type DocumentValidationHistory = {
  _id: string,
  documentId: string,
  status: DocumentValidationStatus,
  rejectionReason: string,
  obs: string,
  nifValidator: string,
  nameValidator: string,
  validatedAt: Date,
  createdAt: Date,
  updatedAt: Date,
}

export enum DocumentValidationStatus {
  ACCEPTED = 'ACEITAR',
  REJECTED = 'REJEITAR',
}

export enum DocumentValidationExemptionReason {
  REASON_1 = 'Razão 1',
  REASON_2 = 'Razão 2',
  REASON_3 = 'Razão 3',
}

export type DocumentValidationInputDTO = {
  status: string;
  obs: string;
  rejectionReason?: string;
}