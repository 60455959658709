<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documentos">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 text-right">
    <app-button-route
      label="Voltar"
      color="light"
      routerLink="/documentos"
      iconLeft="fa-arrow-circle-left">
    </app-button-route>
    <app-daf-validation
      *ngIf="document && auth.profile.rule === 'MINISTRY'"
      [documentId]="document._id"
      (updateDocument)="onValidate($event)">
    </app-daf-validation>
    <app-budget-validation
      *ngIf="document && auth.profile.rule === 'BUDGET'"
      [documentId]="document._id"
      (updateDocument)="onValidate($event)">
    </app-budget-validation>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <div class="p-grid">
          <div class="p-col-12">
            <table mdbTable>
              <tbody>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bolder border-0" [ngStyle]="{width: '25%'}">REFERÊNCIA</th>
                <td class="border-0">{{ document?.reference }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">EMITENTE</th>
                <td>{{ document?.emitterNif }} - {{ document?.emitterName }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">ADQUIRENTE</th>
                <td>
                  {{ document?.direction?.nif }} - {{ document?.direction?.name }}
                </td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">DATA DE EMISSÃO</th>
                <td>{{ document?.date }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">MONTANTE</th>
                <td>{{ document?.amount | number : "0.2-6" : "pt"}}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">IVA</th>
                <td>{{ document?.taxAmount | number : "0.2-6" : "pt"}}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">TOTAL</th>
                <td>{{ document?.totalAmount | number : "0.2-6" : "pt"}}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">DATA DE COMUNICAÇÃO</th>
                <td>{{ document?.createdAt | date : "dd/MM/yyyy hh:mm:ss"}}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">DATA DA ÚLTIMA ATUALIZAÇÃO</th>
                <td>{{ document?.createdAt | date : "dd/MM/yyyy hh:mm:ss"}}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">VALIDAÇÃO DA DAF</th>
                <td>
                  <ng-container *ngIf="document?.dafValidation?.status === 'ACCEPTED'">
                    <p-tag severity="success" value="ACEITO"></p-tag>
                  </ng-container>
                  <ng-container *ngIf="document?.dafValidation?.status === 'REJECTED'">
                    <p-tag severity="danger" value="REJEITADO"></p-tag>
                  </ng-container>
                  <ng-container *ngIf="document?.dafValidation?.status === 'PENDING'">
                    <p-tag severity="warning" value="PENDENTE"></p-tag>
                  </ng-container>
                </td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">VALIDAÇÃO DO ORÇAMENTO</th>
                <td>
                  <ng-container *ngIf="document?.budgetValidation?.status === 'ACCEPTED'">
                    <p-tag severity="success" value="ACEITO"></p-tag>
                  </ng-container>
                  <ng-container *ngIf="document?.budgetValidation?.status === 'REJECTED'">
                    <p-tag severity="danger" value="REJEITADO"></p-tag>
                  </ng-container>
                  <ng-container *ngIf="document?.budgetValidation?.status === 'PENDING'">
                    <p-tag severity="warning" value="PENDENTE"></p-tag>
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-col-12">
            <p-tabView>
              <p-tabPanel header="ITENS">
                 <app-document-items *ngIf="document" [items]="document?.items"></app-document-items>
              </p-tabPanel>
              <p-tabPanel header="HISTÓRICO DE VALIDAÇÕES">
                  <app-document-validation-history *ngIf="validationHistories?.length" [histories]="validationHistories">
                  </app-document-validation-history>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
