import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() label: string;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() color: 'primary' | 'light' | 'danger' | 'teal' = 'light';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() iconRight: string = null;
  @Input() iconLeft: string = null;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
