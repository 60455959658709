import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { HistoricRoutingModule } from './historic-routing.module';
import { HistoricComponent } from './historic.component';
import { HistoricService } from './historic.service';
import { AppButtonModule } from '../shared/components/app-button/app-button.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
@NgModule({
  declarations: [
    HistoricComponent
  ],
  imports: [
    CommonModule,
    HistoricRoutingModule,
    MDBBootstrapModule,
    TableModule,
    DialogModule,
    InputTextModule,
    AppButtonModule,
    NgxJsonViewerModule
  ],
  providers: [HistoricService]
})
export class HistoricModule { }
