<app-top-bar></app-top-bar>

<app-nav-bar></app-nav-bar>

<main id="main">
  <section class="section">
    <router-outlet></router-outlet>
  </section>
</main>

<p-toast
  [style]="{ marginTop: '80px' }"
  styleClass="custom-toast"
  position="bottom-right"
></p-toast>

<p-toast
  [style]="{ marginTop: '80px', width: '600px' }"
  key="lg"
  styleClass="custom-toast"
  position="bottom-right"
  preventOpenDuplicates="true"
>
</p-toast>

<p-confirmDialog
  header="Confirmação"
  icon="pi pi-question-circle"
  #confirmacao
  [style]="{ width: '50vw' }"
>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Sim"
      (click)="confirmacao.accept()"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="Não"
      (click)="confirmacao.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>

<app-footer></app-footer>
