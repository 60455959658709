import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { catchError, map, Observable, of } from 'rxjs';
import { TaxpayerService } from './taxpayer.service';
import { TaxpayerOutputDTO } from '../core/types/taxpayer';

@Injectable({
  providedIn: 'root',
})
export class TaxIdValidator implements AsyncValidator {
  private taxpayer: TaxpayerOutputDTO | undefined;

  constructor(private taxpayerService: TaxpayerService) {}

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.taxpayerService.getTaxpayerByTaxId(control.value).pipe(
      map((taxpayer) => {
        this.taxpayer = taxpayer;
        return taxpayer ? null : { invalid: true };
      }),
      catchError(() => of({ invalid: true }))
    );
  }

  getTaxpayer() {
    return this.taxpayer;
  }
}
