import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxpayerService } from './taxpayer.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [TaxpayerService]
})
export class TaxpayerModule { }
