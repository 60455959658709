<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/tecnicos">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TÉCNICOS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DETALHES
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12 text-right">
    <app-button-route
      label="Voltar"
      color="light"
      routerLink="/tecnicos"
      iconLeft="fa-arrow-circle-left">
    </app-button-route>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <div class="p-grid">
          <div class="p-col-12">
            <table mdbTable>
              <tbody>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bolder border-0" [ngStyle]="{width: '20%'}">NIF</th>
                <td class="border-0">{{ employee?.nif }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">NOME</th>
                <td>{{ employee?.name }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">GESTOR</th>
                <td>
                  <p-inputSwitch
                    name="isManager"
                    [(ngModel)]="isManagerCheck"
                    (onChange)="setManager($event)"
                    [disabled]="!auth.profile.isManager"
                ></p-inputSwitch>
                </td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">ATIVAÇÃO</th>
                <td>
                  <p-inputSwitch
                    name="isActive"
                    [(ngModel)]="isActiveCheck"
                    (onChange)="setActivation($event)"
                    [disabled]="!auth.profile.isManager"
                ></p-inputSwitch>
                </td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">DAF</th>
                <td>
                  {{ employee?.ministry?.nif }} -
                  {{ employee?.ministry?.name }}
                </td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">DATA DE REGISTO</th>
                <td>{{ createdAt }}</td>
              </tr>
              <tr mdbTableCol>
                <th scope="row" class="font-weight-bold">ÚLTIMA ATUALIZAÇÃO</th>
                <td>{{ updatedAt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
