import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message/message.component';
import { TagModule } from 'primeng/tag';

@NgModule({
  declarations: [MessageComponent],
  imports: [
    CommonModule,
    TagModule
  ],
  exports: [MessageComponent, TagModule]
})
export class SharedModule { }
