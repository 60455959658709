import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DocumentRoutingModule } from './document-routing.module';
import { DocumentComponent } from './document.component';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { DetailDocumentComponent } from './detail-document/detail-document.component';
import { AppButtonModule } from '../shared/components/app-button/app-button.module';
import { TabViewModule } from 'primeng/tabview';
import { DocumentItemsComponent } from './document-items/document-items.component';
import { ValidationService } from './validation.service';
import { DocumentService } from './document.service';
import { DocumentValidationHistoryComponent } from './document-validation-history/document-validation-history.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { DafValidationComponent } from './daf-validation/daf-validation.component';
import { BudgetValidationComponent } from './budget-validation/budget-validation.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    DocumentComponent,
    DetailDocumentComponent,
    DocumentItemsComponent,
    DocumentValidationHistoryComponent,
    DafValidationComponent,
    BudgetValidationComponent
  ],
  imports: [
    CommonModule,
    DocumentRoutingModule,

    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    TableModule,
    InputTextModule,
    TagModule,
    AppButtonModule,
    TabViewModule,
    DialogModule,
    ButtonModule,
    TooltipModule,
    InputTextareaModule,
    DropdownModule,
    InputSwitchModule
  ],
  exports: [],
  providers: [DocumentService, ValidationService]
})
export class DocumentModule { }
