<header id="header">
  <div class="container">
    <div id="logo" class="pull-left">
      <h1>
        <a routerLink="/" class="scrollto">e<span>Factura</span></a>
        <span>Reconhecimento das Facturas Emitidas Ao Estado</span>
      </h1>
    </div>

    <nav id="nav-menu-container">
      <ul *ngIf="isLoggedIn && profile?.isActive" class="nav-menu">
        <li routerLinkActive="menu-active">
          <a routerLink="/">Início</a>
        </li>
        <li routerLinkActive="menu-active">
          <a routerLink="/documentos">Documentos</a>
        </li>
        <li *ngIf="profile?.rule === 'ADMIN'" routerLinkActive="menu-active">
          <a routerLink="/dafs">DAFs</a>
        </li>
        <li *ngIf="profile?.rule === 'MINISTRY'" routerLinkActive="menu-active">
          <a routerLink="/directions">Direcções</a>
        </li>
        <li routerLinkActive="menu-active">
          <a routerLink="/tecnicos">Técnicos</a>
        </li>
        <li routerLinkActive="menu-active">
          <a routerLink="/historico"> Histórico </a>
        </li>
      </ul>
    </nav>
  </div>
</header>
