import { Component, Input, OnInit } from '@angular/core';
import { ValidationService } from '../validation.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { finalize } from 'rxjs';
import { DocumentValidationExemptionReason, DocumentValidationHistory } from 'src/app/core/types/document';

@Component({
  selector: 'app-document-validation-history',
  templateUrl: './document-validation-history.component.html',
  styleUrls: ['./document-validation-history.component.scss']
})
export class DocumentValidationHistoryComponent {
  @Input() histories: DocumentValidationHistory[];

  history: DocumentValidationHistory;

  loading = false;
  displayModal = false;

  constructor() { }

  showMoreDetailsInModalDialog(history: DocumentValidationHistory) {
    this.history = history;
    this.displayModal = true;
  }

  getExemptionReason(reason: DocumentValidationExemptionReason) {
    return DocumentValidationExemptionReason[reason];
  }
}
