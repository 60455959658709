import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DocumentOutputDTO, DocumentValidationHistory } from 'src/app/core/types/document';
import { DocumentService } from '../document.service';
import { ValidationService } from '../validation.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-detail-document',
  templateUrl: './detail-document.component.html',
  styleUrls: ['./detail-document.component.scss']
})
export class DetailDocumentComponent implements OnInit {
  loading: boolean = false;

  document: DocumentOutputDTO;

  validationHistories: DocumentValidationHistory[];

  constructor(
    private documentService: DocumentService,
    private validationService: ValidationService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getById(params['id']);
      this.loadHistories(params['id']);
    })
  }

  getById(id: string) {
    this.loading = true;
    this.documentService
      .getDocumentById(id)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => {
          this.document = data;
        },
        error: (error) => this.errorHandler.handle(error),
      })
  }

  loadHistories(documentId: string) {
    this.loading = true;
    this.validationService
      .loadDocumentHistories(documentId)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.validationHistories = data,
        error: (error) => this.errorHandler.handle(error),
      })
  }

  onValidate(document: DocumentOutputDTO) {
    this.document = document;
    this.loadHistories(document._id);
  }
}
