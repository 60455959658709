import { Component, OnInit } from '@angular/core';
import { EmployeeOutputDTO } from '../core/types/employee';
import { ErrorHandlerService } from '../core/error-handler.service';
import { EmployeeService } from './employee.service';
import { finalize } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  loading = false;
  data: EmployeeOutputDTO[] = [];

  constructor(
    private employeeService: EmployeeService,
    private errorHandler: ErrorHandlerService,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.loadEmployees();
  }

  loadEmployees() {
    this.loading = true;
    this.employeeService.loadEmployees()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.data = data,
        error: (error) => this.errorHandler.handle(error),
      })
  }
}
