import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppHttp } from './app-http';
import { Profile } from '../core/types/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  API_URL: string;

  profile: Profile;
  isLoggedIn: boolean;

  constructor(
    private keycloakService: KeycloakService,
    private http: AppHttp
  ) {
    this.API_URL = `${environment.apiUrl}/users`;

    this.getProfileFromStorage();
  }

  public async login(): Promise<void> {
    return this.keycloakService.login();
  }

  public async logout(): Promise<void> {
    localStorage.clear();
    return this.keycloakService.logout(window.location.origin);
  }

  public async loadUserProfile(): Promise<Keycloak.KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public redirectToProfile(): Keycloak.KeycloakPromise<void, void> {
    return this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public redirectToLogin(): void {
    this.keycloakService.login();
  }

  public getRules(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public getGroups(): string[] {
    return this.getLoggedUser()?.groups;
  }
  
  public getLoggedUser(): Keycloak.KeycloakTokenParsed | undefined {
    try {
      const userDetails =
        this.keycloakService.getKeycloakInstance().idTokenParsed;
      return userDetails;
    } catch (error) {
      return undefined;
    }
  }

  public async VerifyIfIsLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }

  public hasPermission(rule: string): boolean {
    return this.keycloakService.getUserRoles().includes(rule);
  }

  public hasGroup(group: string) {
    return this.getGroups().includes(group);
  }

  getProfile(): Observable<Profile> {
    return this.http.get<Profile>(`${this.API_URL}/profile`);
  }

  async setProfileInStorage() {
    this.getProfile().subscribe((profile) => {
      localStorage.setItem('profile', JSON.stringify(profile));
    });
  }

  getProfileFromStorage() {
    this.VerifyIfIsLoggedIn()
    .then(async (result) => {
      if (!result) {
        localStorage.removeItem('profile');
        this.profile = null;
        this.isLoggedIn = false;
      } else {
        await this.setProfileInStorage();
        this.profile = JSON.parse(localStorage.getItem('profile')) as Profile;
        this.isLoggedIn = true;
      }
    }).catch(() => {
      localStorage.removeItem('profile');
      this.profile = null;
      this.isLoggedIn = false;
    });
  }
}
