import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { EmployeeComponent } from './employee.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { DetailEmployeeComponent } from './detail-employee/detail-employee.component';
import { ManagerGuard } from '../auth/manager.guard';

const routes: Routes = [
  {
    path: '',
    component: EmployeeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'adicionar',
    component: CreateEmployeeComponent,
    canActivate: [AuthGuard, ManagerGuard],
  },
  {
    path: ':id',
    component: DetailEmployeeComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmployeeRoutingModule { }
