<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/dafs">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DIRECÇÕES
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADICIONAR
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form
          [formGroup]="directionForm"
          (ngSubmit)="addDirection()"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
              <label>NIF</label>
              <input
                pInputText
                type="text"
                name="nif"
                formControlName="nif"
              />
            </div>

            <div class="p-field p-col-12 p-md-8">
              <label>Nome</label>
              <input
                pInputText
                type="text"
                name="name"
                formControlName="name"
              />
            </div>

            <div class="p-field p-col-12 p-md-2">
              <label>Acrónimo</label>
              <input
                pInputText
                name="acronym"
                formControlName="acronym"
              />
            </div>

            <div class="p-col-12 text-right">
              <hr />
              <app-button-route
                label="Cancelar"
                color="light"
                routerLink="/directions"
                iconLeft="fa-arrow-circle-left">
              </app-button-route>

              <app-button
                type="submit"
                label="Salvar"
                color="primary"
                iconRight="fa-check"
                [loading]="loading"
                [disabled]="isDisabled()">
              </app-button>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
