import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaxIdValidator } from "../taxpayer/tax-id.validator";
import { DafInputDTO } from "../core/types/daf";

@Injectable({
  providedIn: 'root',
})
export class DafForm {
  form: FormGroup;

  constructor(
    private taxIdValidator: TaxIdValidator,
  ) { }

  buildForm() {
    return this.form = new FormGroup({
      name: new FormControl({value: '', disabled: true}),
      nif: new FormControl('', {
        validators: [Validators.required, Validators.pattern(/^\d{9}$/)],
        asyncValidators: [this.taxIdValidator.validate.bind(this.taxIdValidator)],
        updateOn: 'blur',
      }),
      acronym: new FormControl('', [Validators.required]),
      defaultEmployee: new FormGroup({
        name: new FormControl({value: '', disabled: true}),
        nif: new FormControl('', {
          validators: [Validators.required, Validators.pattern(/^\d{9}$/)],
          asyncValidators: [this.taxIdValidator.validate.bind(this.taxIdValidator)],
          updateOn: 'blur',
        }),
      }),
    });
  }

  fillTaxpayerName() {
    this.form.get('nif')?.statusChanges.subscribe((status) => {
      this.form.get('name')?.reset();
      if (status === 'VALID') {
        const taxpayer = this.taxIdValidator.getTaxpayer();
        if (taxpayer) {
          this.form.get('name')?.setValue(taxpayer.name);
        }
      }
    });

    this.form.get('defaultEmployee.nif')?.statusChanges.subscribe(status => {
      this.form.get('defaultEmployee.name')?.reset();
      if (status === 'VALID') {
        const taxpayerData = this.taxIdValidator.getTaxpayer();
        if (taxpayerData) {
          this.form.get('defaultEmployee.name')?.setValue(taxpayerData.name);
        }
      }
    });
  }

  parseForm(): DafInputDTO {
    return {
      nif: this.taxId.value,
      acronym: this.acronym.value,
      defaultEmployee: {
        nif: this.defaultEmployeeTaxId.value,
      }
    }
  }

  get taxId() {
    return this.form.get('nif') as FormControl<string>;
  }

  get name() {
    return this.form.get('name') as FormControl<string>;
  }

  get acronym() {
    return this.form.get('acronym') as FormControl<string>;
  }

  get defaultEmployee() {
    return this.form.get('defaultEmployee') as FormGroup;
  }

  get defaultEmployeeName() {
    return this.defaultEmployee.get('name') as FormControl<string>;
  }

  get defaultEmployeeTaxId() {
    return this.defaultEmployee.get('nif') as FormControl<string>;
  }
}