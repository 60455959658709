import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { AuthModule } from '../auth/auth.module';
import { AuthService } from '../auth/auth.service';
import { DocumentModule } from '../document/document.module';
import { ErrorHandlerService } from './error-handler.service';
import { TaxpayerModule } from '../taxpayer/taxpayer.module';
import { DafModule } from '../daf/daf.module';
import { EmployeeModule } from '../employee/employee.module';
import { DirectionModule } from '../direction/direction.module';
import { DateService } from './date.service';
import { AppMessageService } from './app-message.service';
import { HistoricModule } from '../historic/historic.module';

registerLocaleData(localePt);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    HttpClientModule,
    ToastModule,
    ConfirmDialogModule,

    AuthModule,
    DocumentModule,
    DafModule,
    TaxpayerModule,
    EmployeeModule,
    DirectionModule,
    HistoricModule,
    
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [ToastModule, ConfirmDialogModule],
  providers: [
    Title,
    { provide: LOCALE_ID, useValue: 'pt' },
    ErrorHandlerService,
    JwtHelperService,
    MessageService,
    ConfirmationService,
    AuthService,
    TranslateService,
    DateService,
    AppMessageService,
  ],
})
export class CoreModule {}
