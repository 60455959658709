<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTOS
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>
  
  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body class="mdb-card-body p-4">
        <div class="p-col-6">
          <input pInputText class="p-inputtext-sm" name="search" placeholder="Search">
        </div>
        <div class="p-col-12">
          <p-table
            styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
            [value]="documentPaginated?.content"
            [loading]="loading"
            [paginator]="true"
            [rows]="filter?.size"
            [lazy]="true"
            [totalRecords]="documentPaginated?.totalElements"
            (onLazyLoad)="onChangePage($event)"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Referência</th>
              <th>Emitente</th>
              <th>Adquirente</th>
              <th>Data Emissão</th>
              <th>Montante</th>
              <th>IVA</th>
              <th>Total</th>
              <th>Val. DAF</th>
              <th>Val. Orçamento</th>
              <th [style]="{ width: '3em' }"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title">Nº Documento</span>
                {{ rowData.reference }}
              </td>
              <td>
                <span class="p-column-title">Emitente</span>
                {{ rowData.emitterNif }} - {{ rowData.emitterName }}
              </td>
              <td>
                <span class="p-column-title">Adquirente</span>
                {{ rowData.direction?.nif }} - {{ rowData.direction?.name }}
              <td>
                <span class="p-column-title">Data Emissão</span>
                {{ rowData.date }}
              </td>
              <td>
                <span class="p-column-title">Montante</span>
                {{ rowData.amount | number : "0.2-6" : "pt"}}
              </td>
              <td>
                <span class="p-column-title">IVA</span>
                {{ rowData.taxAmount | number : "0.2-6" : "pt" }}
              </td>
              <td>
                <span class="p-column-title">Total</span>
                {{ rowData.totalAmount | number : "0.2-6" : "pt" }}
              </td>
              <td>
                <span class="p-column-title">Val. DAF</span>
                <ng-container *ngIf="rowData.dafValidation.status === 'ACCEPTED'">
                  <p-tag severity="success" value="ACEITO"></p-tag>
                </ng-container>
                <ng-container *ngIf="rowData.dafValidation.status === 'REJECTED'">
                  <p-tag severity="danger" value="REJEITADO"></p-tag>
                </ng-container>
                <ng-container *ngIf="rowData.dafValidation.status === 'PENDING'">
                  <p-tag severity="warning" value="PENDENTE"></p-tag>
                </ng-container>
              </td>
              <td>
                <span class="p-column-title">Val.Orçamento</span>
                <ng-container *ngIf="rowData.budgetValidation.status === 'ACCEPTED'">
                  <p-tag severity="success" value="ACEITO"></p-tag>
                </ng-container>
                <ng-container *ngIf="rowData.budgetValidation.status === 'REJECTED'">
                  <p-tag severity="danger" value="REJEITADO"></p-tag>
                </ng-container>
                <ng-container *ngIf="rowData.budgetValidation.status === 'PENDING'">
                  <p-tag severity="warning" value="PENDENTE"></p-tag>
                </ng-container>
              </td>
              <td [style]="{ padding: '1px 4px' }">
                <button
                  type="button"
                  mdbBtn
                  color="info"
                  size="sm"
                  pTooltip="Ver mais informações"
                  tooltipPosition="left"
                  class="btn-block"
                  [style]="{ padding: '4px 0' }"
                  [routerLink]="[
                    '/documentos',
                    rowData._id
                  ]"
                >
                  <i class="fa fa-search"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
