import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-route',
  templateUrl: './button-route.component.html',
  styleUrls: ['./button-route.component.scss']
})
export class ButtonRouteComponent implements OnInit {
  @Input() label: string;
  @Input() routerLink: string;
  @Input() color: 'primary' | 'light' | 'danger' | 'teal' = 'light';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() iconRight: string = null;
  @Input() iconLeft: string = null;
  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
