import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DirectionService } from '../direction.service';
import { DirectionForm } from '../direction.form';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { DirectionInputDTO } from 'src/app/core/types/direction';
import { finalize } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-direction',
  templateUrl: './create-direction.component.html',
  styleUrls: ['./create-direction.component.scss']
})
export class CreateDirectionComponent implements OnInit {
  loading = false;
  directionForm: FormGroup;

  constructor(
    private directionService: DirectionService,
    private form: DirectionForm,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.directionForm = this.form.buildForm();
    this.form.fillTaxpayerName();
  }

  isDisabled() {
    return this.directionForm.invalid || this.loading;
  }

  addDirection() {
    if (this.directionForm.valid) {
      this.loading = true;
      const data: DirectionInputDTO = this.form.parseForm();
      
      this.directionService
        .createDirection(data)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (data) => this.router.navigate(['directions', data._id]),
          error: (error) => this.errorHandler.handle(error),
        });
    }
  }
}
