import { Component, OnInit } from '@angular/core';
import { HistoricFIlter, HistoricPaginated } from '../core/types/historic';
import { HistoricService } from './historic.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { finalize } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss']
})
export class HistoricComponent implements OnInit {
  loading = false;
  displayModal = false;
  totalElements = 0;
  filter: HistoricFIlter;
  historicPaginated: HistoricPaginated;

  dataAfter: object;

  constructor(
    private historicService: HistoricService,
    private errorHandler: ErrorHandlerService
  ) { this.filter = { page: 0, size: 10 }; }

  ngOnInit(): void {
  }

  loadHistories(page = 0) {
    this.loading = true;
    this.filter.page = page;

    this.historicService.loadHistories(this.filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.historicPaginated = data,
        error: (err) => this.errorHandler.handle(err),
      })
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.loadHistories(page);
  }

  showDataInModal(dataAfter: object) {
    this.dataAfter = dataAfter;
    this.displayModal = true;
  }
}
