import { Component, OnInit } from '@angular/core';
import { DirectionOutputDTO } from 'src/app/core/types/direction';
import { DirectionService } from '../direction.service';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-detail-direction',
  templateUrl: './detail-direction.component.html',
  styleUrls: ['./detail-direction.component.scss']
})
export class DetailDirectionComponent implements OnInit {
  loading: boolean = false;

  direction: DirectionOutputDTO;

  constructor(
    private directionService: DirectionService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getById(params['id']);
    })
  }

  getById(id: string) {
    this.loading = true;
    this.directionService
      .getDirectionById(id)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.direction = data,
        error: (error) => this.errorHandler.handle(error),
      })
  }
}
