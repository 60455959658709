import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TableBasicComponent } from './table-basic/table-basic.component';

@NgModule({
  declarations: [
    TableBasicComponent
  ],
  imports: [
    CommonModule,
    MDBBootstrapModule
  ],
  exports: [TableBasicComponent]
})
export class AppTableModule { }
