import { Component, OnInit } from '@angular/core';
import { DocumentFIlter, DocumentPaginated } from '../core/types/document';
import { DocumentService } from './document.service';
import { finalize } from 'rxjs';
import { ErrorHandlerService } from '../core/error-handler.service';
import { LazyLoadEvent } from 'primeng/api';
import { DateService } from '../core/date.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  loading = false;

  totalElements = 0;

  filter: DocumentFIlter;
  documentPaginated: DocumentPaginated;

  constructor(
    private documentService: DocumentService,
    private errorHandler: ErrorHandlerService,
  ) { this.filter = { page: 0, size: 10 }; }

  ngOnInit(): void {
  }

  loadDocuments(page = 0) {
    this.loading = true;
    this.filter.page = page;

    this.documentService.loadDocuments(this.filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.documentPaginated = data,
        error: (error) => this.errorHandler.handle(error),
      })
  }

  onChangePage(event: LazyLoadEvent) {
    const page = event.first / event.rows;
    this.loadDocuments(page);
  }
}
