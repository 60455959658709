import { Injectable } from '@angular/core';
import { AppHttp } from '../auth/app-http';
import { DateService } from '../core/date.service';
import { environment } from 'src/environments/environment';
import { DocumentOutputDTO, DocumentValidationHistory, DocumentValidationInputDTO } from '../core/types/document';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  API_URL: string;

  constructor(
    private httpClient: AppHttp,
  ) {
    this.API_URL = `${environment.apiUrl}/documents/validation`;
  }

  loadDocumentHistories(documentId: string): Observable<DocumentValidationHistory[]> {
    let params = new HttpParams()
    params = params.append('documentId', documentId);
    return this.httpClient.get<DocumentValidationHistory[]>(`${this.API_URL}/histories`, { params })
  }

  dafValidation(documentId: string, validation: DocumentValidationInputDTO): Observable<DocumentOutputDTO> {
    return this.httpClient.patch<DocumentOutputDTO>(`${this.API_URL}/daf/${documentId}`, validation)
  }

  budgetValidation(documentId: string, validation: DocumentValidationInputDTO): Observable<DocumentOutputDTO> {
    return this.httpClient.patch<DocumentOutputDTO>(`${this.API_URL}/budget/${documentId}`, validation)
  }
}
