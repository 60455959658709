import { Component, OnInit } from '@angular/core';
import { DafService } from './daf.service';
import { DafOutputDTO } from '../core/types/daf';
import { ErrorHandlerService } from '../core/error-handler.service';
import { finalize } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-daf',
  templateUrl: './daf.component.html',
  styleUrls: ['./daf.component.scss']
})
export class DafComponent implements OnInit {
  loading = false;
  data: DafOutputDTO[] = [];

  constructor(
    private dafService: DafService,
    private errorHandler: ErrorHandlerService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.loadDafs();
  }

  loadDafs() {
    this.loading = true;
    this.dafService.loadDafs()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.data = data,
        error: (error) => this.errorHandler.handle(error),
      })
  }

  canAdd() {
    return this.auth.profile.rule === 'ADMIN' || 
      this.auth.profile.rule === 'AGENT'
  }
}
