import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { HistoricComponent } from './historic.component';

const routes: Routes = [
  {
    path: '',
    component: HistoricComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class HistoricRoutingModule { }
