import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DafInputDTO, DafOutputDTO } from '../core/types/daf';

@Injectable({
  providedIn: 'root'
})
export class DafService {
  apiURL: string;

  constructor(private httpClient: HttpClient) { 
    this.apiURL = `${environment.apiUrl}/ministries`;
  }

  loadDafs(): Observable<DafOutputDTO[]> {
    return this.httpClient.get<DafOutputDTO[]>(this.apiURL);
  }

  getDafById(id: string): Observable<DafOutputDTO> {
    return this.httpClient.get<DafOutputDTO>(`${this.apiURL}/${id}`);
  }

  createDaf(data: DafInputDTO): Observable<DafOutputDTO> {
    return this.httpClient.post<DafOutputDTO>(this.apiURL, data);
  }
}
