import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { EmployeeService } from '../employee.service';
import { finalize } from 'rxjs';
import { EmployeeOutputDTO } from 'src/app/core/types/employee';
import { DateService } from 'src/app/core/date.service';
import { FormControl } from '@angular/forms';
import { AppMessageService } from 'src/app/core/app-message.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-detail-employee',
  templateUrl: './detail-employee.component.html',
  styleUrls: ['./detail-employee.component.scss']
})
export class DetailEmployeeComponent implements OnInit {
  loading: boolean = false;

  isActiveCheck = false;
  isManagerCheck = false;

  createdAt: string;
  updatedAt: string;
  
  employee: EmployeeOutputDTO;

  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private dateService: DateService,
    private messageService: AppMessageService,
    private errorHandler: ErrorHandlerService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getById(params['id']);
    })
  }

  getById(id: string) {
    this.loading = true;
    this.employeeService
      .getEmployeeById(id)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => {
          this.employee = data;
          this.createdAt = this.getRelativeTime(new Date(this.employee.createdAt));
          this.updatedAt = this.getRelativeTime(new Date(this.employee.updatedAt));
          this.isActiveCheck = this.employee.isActive;
          this.isManagerCheck = this.employee.isManager;
        },
        error: (error) => this.errorHandler.handle(error),
      })
  }

  getRelativeTime(date: Date) {
    return this.dateService.getRelativeTime(date).toUpperCase();
  }

  setActivation(event) {
    this.isActiveCheck = event?.checked;
    const activationText = this.isActiveCheck ? 'ativo' : 'inativo';

    this.employeeService
      .setActivation(this.employee._id, this.isActiveCheck)
      .subscribe({
        next: () => {
          this.employee.isActive = this.isActiveCheck;
          this.messageService.addSuccess({
            summary: 'Ativação de Técnico', 
            detail: `Técnico definido como ${activationText}`
          })
        },
        error: (error) => {
          this.isActiveCheck = !this.isActiveCheck;
          this.errorHandler.handle(error);
        },
      })
  }

  setManager(event) {
    this.isManagerCheck = event?.checked;
    const managerText = this.isManagerCheck 
      ? 'O técnico agora é um gestor' 
      : 'O técnico deixou de ser um gestor';

    this.employeeService
      .setManager(this.employee._id, this.isManagerCheck)
      .subscribe({
        next: () => {
          this.employee.isManager = this.isManagerCheck;
          this.messageService.addSuccess({
            summary: 'Definição de Gestor', 
            detail: managerText
          })
        },
        error: (error) => {
          this.isManagerCheck = !this.isManagerCheck;
          this.errorHandler.handle(error);
        },
      })
  }
}
