import { Component, Input, OnInit } from '@angular/core';
import { DocumentOutputDTO } from 'src/app/core/types/document';

@Component({
  selector: 'app-document-items',
  templateUrl: './document-items.component.html',
  styleUrls: ['./document-items.component.scss']
})
export class DocumentItemsComponent implements OnInit {
  @Input() items: DocumentOutputDTO['items'];

  loading = false;

  constructor() { }

  ngOnInit(): void {
  }

}
