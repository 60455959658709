import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeService } from './employee.service';
import { DetailEmployeeComponent } from './detail-employee/detail-employee.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { EmployeeComponent } from './employee.component';
import { EmployeeRoutingModule } from './employee-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import { AppButtonModule } from '../shared/components/app-button/app-button.module';

@NgModule({
  declarations: [
    DetailEmployeeComponent,
    CreateEmployeeComponent,
    EmployeeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EmployeeRoutingModule,
    MDBBootstrapModule,
    TableModule,
    InputTextModule,
    AppButtonModule,
    InputSwitchModule
  ],
  providers: [EmployeeService]
})
export class EmployeeModule { }
