import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-basic',
  templateUrl: './table-basic.component.html',
  styleUrls: ['./table-basic.component.scss']
})
export class TableBasicComponent implements OnInit {
  @Input() data: object[] = [];
  @Input() headers: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
