import { Injectable } from "@angular/core";
import { format, formatDistance, setDefaultOptions } from 'date-fns';
import { pt } from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() { 
    setDefaultOptions({ locale: pt });
  }

  getRelativeTime(dateToCompare: number | Date): string {
    const now = new Date();
    return formatDistance(dateToCompare, now, { addSuffix: true });
  }

  getStringDate(date: Date): string {
    return format(date, "dd/MM/yyyy");
  }
}